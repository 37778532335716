<template>
  <v-row class="match-height">
    <v-snackbar
      v-model="notificaciones.snackbar"
      :bottom="notificaciones.y === 'bottom'"
      :color="notificaciones.color"
      :left="notificaciones.x === 'left'"
      :multi-line="notificaciones.mode === 'multi-line'"
      :right="notificaciones.x === 'right'"
      :timeout="notificaciones.timeout"
      :top="notificaciones.y === 'top'"
      :vertical="notificaciones.mode === 'vertical'"
    >
      {{ notificaciones.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="notificaciones.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12">
      <Breadcrumbs :items="items" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title class="mb-4">{{ formTitulo }}</v-card-title>
        <v-card-text>
          <v-form
            id="form"
            ref="form"
            v-model="isValid"
            class="multi-col-validation"
            :loading="loadingSaveForm"
            lazy-validation
          >
            <v-row>
              <!-- Imagen -->
              <v-col
                cols="12"
                md="4"
              >
                <v-card
                  max-width="300"
                  max-height="300"
                  height="300"
                  width="300"
                  class="mx-auto"
                >
                  <v-img
                    v-if="obj.image"
                    :src="obj.image ? obj.image : defaultImage"
                    max-width="300"
                    max-height="300"
                    height="300"
                    width="300"
                    class="text-right"
                  >
                  </v-img>
                </v-card>
              </v-col>

              <v-col
                cols="12"
                md="8"
              >
                <v-row>
                  <!-- Nombre -->
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="obj.fullName"
                      label="Nombre"
                      outlined
                      dense
                      placeholder="Nombre"
                      :disabled="true"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Dirección -->
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="obj.referenceAddress"
                      label="Dirección"
                      outlined
                      dense
                      placeholder="Dirección"
                      :disabled="true"
                      error-count="2"
                      :error="campos.referenceAddress.error"
                      :error-messages="errorMsgReferenceAddress"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Correo -->
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="obj.email"
                      label="Correo"
                      outlined
                      dense
                      placeholder="Correo"
                      :disabled="true"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Codigo -->
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="obj.code"
                      label="Código"
                      outlined
                      dense
                      placeholder="Código"
                      :disabled="true"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Fecha -->
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="obj.date"
                      label="Fecha"
                      outlined
                      dense
                      placeholder="Fecha"
                      :disabled="true"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Telefono -->
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="obj.phone"
                      label="Teléfono"
                      outlined
                      dense
                      placeholder="(09) 99999999"
                      :disabled="true"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Latitude -->
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="obj.latitude"
                      label="Latitud"
                      outlined
                      dense
                      placeholder="Latitud"
                      :disabled="true"
                      error-count="2"
                      :error="campos.latitude.error"
                      :error-messages="errorMsgLatitude"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Longitude -->
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="obj.longitude"
                      label="Longitud"
                      outlined
                      dense
                      placeholder="Longitud"
                      :disabled="true"
                      error-count="2"
                      :error="campos.longitude.error"
                      :error-messages="errorMsgLongitude"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Numero de respuesta -->
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="obj.requestNumber"
                      label="Número de respuesta"
                      outlined
                      dense
                      placeholder="Número de respuesta"
                      :disabled="true"
                      error-count="2"
                      :error="campos.requestNumber.error"
                      :error-messages="errorMsgRequestNumber"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Identificación -->
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="obj.identification"
                      label="Identificación"
                      outlined
                      dense
                      placeholder="Identificación"
                      :disabled="true"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Descripción -->
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="obj.description"
                  label="Descripción"
                  outlined
                  dense
                  placeholder="Descripción"
                  :disabled="true"
                  error-count="2"
                  :error="campos.description.error"
                  :error-messages="errorMsgDescription"
                >
                </v-textarea>
              </v-col>
              <!-- Estado -->
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="estado"
                  label="Estado"
                  outlined
                  dense
                  :items="listaEstados"
                  item-value="id"
                  item-text="name"
                  placeholder="Estado"
                  :disabled="!sendForm"
                  :rules="[
                    (v) => !!v || 'El campo estado es obligatorio',
                  ]"
                  required
                  error-count="2"
                  :error="campos.estado.error"
                  :error-messages="errorMsgEstado"
                ></v-select>
              </v-col>
              <!-- IP -->
              <!-- <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="obj.clientIp"
                  label="Ip"
                  outlined
                  dense
                  placeholder="Ip"
                  :disabled="true"
                >
                </v-text-field>
              </v-col> -->
              <!-- Sub Tipo de incidente -->
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="obj.subTypeIncidentName"
                  label="Sub Tipo de incidente"
                  outlined
                  dense
                  placeholder="Sub Tipo de incidente"
                  :disabled="true"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn
                  v-if="sendForm"
                  color="primary"
                  :disabled="!isValid"
                  :loading="loadingSaveForm"
                  @click="saveForm"
                >
                  Enviar
                </v-btn>
                <v-btn
                  type="reset"
                  outlined
                  class="mx-2"
                  @click="cancelarForm"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { ref, reactive, computed } from '@vue/composition-api'
import moment from 'moment'
import { useRouter } from '@/utils'
import HTTP from '@/utils/axios/axios-config-base'
import AppLabel from '@/components/field/Label'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  components: { AppLabel, Breadcrumbs },
  created() {
    this.listaEstados = this.$route.params.listaEstados.value
    if (this.$route.params.obj != null) {
      this.obj.id = this.$route.params.obj.id
      this.obj.code = this.$route.params.obj.code
      this.obj.description = this.$route.params.obj.description
      this.obj.latitude = this.$route.params.obj.latitude
      this.obj.longitude = this.$route.params.obj.longitude
      this.obj.referenceAddress = this.$route.params.obj.referenceAddress
      this.obj.date = moment(this.$route.params.obj.date).format('DD-MM-YYYY')
      this.obj.image = this.$route.params.obj.image
      this.obj.requestNumber = this.$route.params.obj.requestNumber
      this.obj.stateIncident = this.$route.params.obj.stateIncident
      this.obj.clientIp = this.$route.params.obj.clientIp
      this.obj.subTypeIncidentName = this.$route.params.obj.subTypeIncidentName
      this.obj.identification = this.$route.params.obj.identification
      this.obj.fullName = this.$route.params.obj.fullName
      this.obj.email = this.$route.params.obj.email
      this.obj.phone = this.$route.params.obj.phone
      this.estado = this.$route.params.obj.stateIncident
      this.formTitulo = this.$route.params.formTitulo
      this.sendForm = this.$route.params.sendForm
      this.isValid = true
      this.items = this.$route.params.items
    }
  },
  setup() {
    const { router } = useRouter()
    const formTitulo = ref('Detalle del Incidente')
    const loadingSaveForm = ref(false)
    const sendForm = ref(false)
    const estado = ref(null)
    const obj = ref({
      id: null,
      code: null,
      description: null,
      latitude: null,
      longitude: null,
      referenceAddress: null,
      date: null,
      image: null,
      requestNumber: null,
      stateIncident: null,
      clientIp: null,
      subTypeIncidentName: null,
      identification: null,
      fullName: null,
      email: null,
      phone: null,
    })
    const defaultImage = require('@/assets/images/default-img.png')
    const form = ref(null)
    const isValid = ref(true)
    const items = reactive([])
    const campos = reactive({
      estado: {
        error: false,
        mensajesError: [],
      },
      latitude: {
        error: false,
        mensajesError: [],
      },
      longitude: {
        error: false,
        mensajesError: [],
      },
      description: {
        error: false,
        mensajesError: [],
      },
      requestNumber: {
        error: false,
        mensajesError: [],
      },
      referenceAddress: {
        error: false,
        mensajesError: [],
      },
    })
    const errorMsgEstado = computed(() => (campos.estado.error ? campos.estado.mensajesError : []))
    const errorMsgLatitude = computed(() => (campos.latitude.error ? campos.latitude.mensajesError : []))
    const errorMsgLongitude = computed(() => (campos.longitude.error ? campos.longitude.mensajesError : []))
    const errorMsgDescription = computed(() => (campos.description.error ? campos.description.mensajesError : []))
    const errorMsgRequestNumber = computed(() => (campos.requestNumber.error ? campos.requestNumber.mensajesError : []))
    const errorMsgReferenceAddress = computed(() => (campos.referenceAddress.error ? campos.referenceAddress.mensajesError : []))
    const listaEstados = ref([])
    function limpiarForm() {
      estado.value = null
      obj.value = {
        id: null,
        code: null,
        description: null,
        latitude: null,
        longitude: null,
        referenceAddress: null,
        date: null,
        image: null,
        requestNumber: null,
        stateIncident: null,
        clientIp: null,
        subTypeIncidentName: null,
        identification: null,
        fullName: null,
        email: null,
        phone: null,
      }
    }
    const notificaciones = reactive({
      snackbar: false,
      color: '',
      mode: '',
      text: '',
      timeout: 3000,
      x: null,
      y: 'top',
    })

    return {
      formTitulo,
      estado,
      obj,
      defaultImage,
      form,
      isValid,
      items,
      campos,
      errorMsgEstado,
      errorMsgLatitude,
      errorMsgLongitude,
      errorMsgDescription,
      errorMsgRequestNumber,
      errorMsgReferenceAddress,
      listaEstados,
      loadingSaveForm,
      sendForm,
      notificaciones,
      saveForm: async () => {
        if (loadingSaveForm.value) return
        if (isValid && form.value.validate()) {
          loadingSaveForm.value = true
          campos.value = reactive({
            estado: {
              error: false,
              mensajesError: [],
            },
            latitude: {
              error: false,
              mensajesError: [],
            },
            longitude: {
              error: false,
              mensajesError: [],
            },
            description: {
              error: false,
              mensajesError: [],
            },
            requestNumber: {
              error: false,
              mensajesError: [],
            },
            referenceAddress: {
              error: false,
              mensajesError: [],
            },
          })
          const url = `${HTTP.defaults.baseURL}incidents-api/incidents/${obj.value.id}`
          const objeto = {}
          objeto.stateIncident = estado.value
          objeto.latitude = obj.value.latitude
          objeto.longitude = obj.value.longitude
          objeto.description = obj.value.description
          objeto.requestNumber = obj.value.requestNumber
          objeto.referenceAddress = obj.value.referenceAddress
          await HTTP.put(url, objeto)
            .then(response => {
              notificaciones.snackbar = true
              notificaciones.color = 'success'
              notificaciones.text = 'El registro ha sido actualizado satisfactoriamente'
              loadingSaveForm.value = false
              router.push({
                name: 'registro_incidente',
                params: {
                  notificaciones: {
                    snackbar: notificaciones.snackbar,
                    text: notificaciones.text,
                    color: notificaciones.color,
                  },
                }, 
              })
            })
            .catch(error => {
              const { errors, title } = error.response.data
              if (errors) {
                let errorDialog = true
                if (errors.stateIncident) {
                  for (let index = 0; index < errors.stateIncident.length; index += 1) {
                    campos.estado.mensajesError.push(errors.stateIncident[index])
                  }
                  errorDialog = false
                  campos.estado.error = true
                }
                if (errors.Latitude) {
                  for (let index = 0; index < errors.Latitude.length; index += 1) {
                    campos.latitude.mensajesError.push(errors.Latitude[index])
                  }
                  errorDialog = false
                  campos.latitude.error = true
                }
                if (errors.Longitude) {
                  for (let index = 0; index < errors.Longitude.length; index += 1) {
                    campos.longitude.mensajesError.push(errors.Longitude[index])
                  }
                  errorDialog = false
                  campos.longitude.error = true
                }
                if (errors.Description) {
                  for (let index = 0; index < errors.Description.length; index += 1) {
                    campos.description.mensajesError.push(errors.Description[index])
                  }
                  errorDialog = false
                  campos.description.error = true
                }
                if (errors.RequestNumber) {
                  for (let index = 0; index < errors.RequestNumber.length; index += 1) {
                    campos.requestNumber.mensajesError.push(errors.RequestNumber[index])
                  }
                  errorDialog = false
                  campos.requestNumber.error = true
                }
                if (errors.ReferenceAddress) {
                  for (let index = 0; index < errors.ReferenceAddress.length; index += 1) {
                    campos.referenceAddress.mensajesError.push(errors.ReferenceAddress[index])
                  }
                  errorDialog = false
                  campos.referenceAddress.error = true
                }
                if (errorDialog) {
                  let error = ''
                  const keysError = Object.keys(errors)
                  for (let index = 0; index < keysError.length; index += 1) {
                    for (let index1 = 0; index1 < errors[keysError[index]].length; index1 += 1) {
                      error += errors[keysError[index]][index1]
                    }
                  }
                  notificaciones.snackbar = true
                  notificaciones.color = 'error'
                  notificaciones.text = error
                }
              }
              loadingSaveForm.value = false
            })
        }
      },
      cancelarForm: () => {
        router.push({ name: 'registro_incidente' })
      },
    }
  },
}
</script>

<style>
.v-messages__message {
  color: var(--v-error-base) !important;
  caret-color: var(--v-error-base) !important;
  line-height: 12px;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
</style>
